import { notification } from './notification'

const request = {
  csrfToken: '',

  initializeCsrfToken() {
    const csrfElement = document.querySelector('[name=csrfmiddlewaretoken]')
    this.csrfToken = csrfElement ? csrfElement.value : ''
  },

  async request(url, method, data = null, headers = {}) {
    notification.loading.value++

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.csrfToken,
        ...headers,
      },
    }

    if (data) {
      options.body = JSON.stringify(data)
    }

    try {
      const response = await fetch(url, options)

      if (!response.ok) {
        let errorData = {}

        try {
          errorData = await response.json()
        } catch (e) {
          // If response is not JSON, use status text
          errorData.detail = response.statusText
        }

        throw new Error(errorData.detail || 'Eroare de server')
      }

      if ([204, 205, 304, 100, 101, 102, 103].includes(response.status)) {
        return null
      }

      return response.json()
    } catch (error) {
      notification.toast.add({
        severity: 'error',
        summary: 'Eroare',
        detail: error.message,
        life: 5000,
      })

      // console.error('API request error:', error)
      throw error
    } finally {
      notification.loading.value--
    }
  },

  get(url, data = {}, headers = {}) {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value != null)
    )
    const queryString = new URLSearchParams(filteredData).toString()
    const urlWithParams = queryString ? `${url}?${queryString}` : url

    return this.request(urlWithParams, 'GET', null, headers)
  },

  post(url, data, headers = {}) {
    return this.request(url, 'POST', data, headers)
  },

  put(url, data, headers = {}) {
    return this.request(url, 'PUT', data, headers)
  },

  patch(url, data, headers = {}) {
    return this.request(url, 'PATCH', data, headers)
  },

  delete(url, headers = {}) {
    return this.request(url, 'DELETE', null, headers)
  },
}

document.addEventListener('DOMContentLoaded', () =>
  request.initializeCsrfToken()
)

export default request
