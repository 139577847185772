import { createRouter, createWebHistory } from 'vue-router'

const checkSteps = (to, from, next) => {
  if (!to.query.step || !to.query.type) {
    next({
      ...to,
      query: { step: 1, type: to.meta.defaultType, ...to.query },
    })
  } else if (!to.query.id && to.query.step == '2') {
    next({ ...to, query: { ...to.query, step: 1 } })
  } else {
    next()
  }
}

const checkPayment = (to, from, next) => {
  const validPayments = ['card', 'transfer', 'sponsorship']

  if (!to.query.type || !validPayments.includes(to.query.type)) {
    next({ ...to, query: { ...to.query, type: 'card' } })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/inscriere',
    component: () => import('./BaseSteps.vue'),
    redirect: { name: 'registration', query: { step: 1 } },
    meta: { defaultType: 'individual' },

    children: [
      {
        path: '',
        name: 'registration',
        beforeEnter: checkSteps,
        component: () => import('./RegistrationFormBase.vue'),
      },
    ],
  },
  {
    path: '/comanda',
    component: () => import('./BaseSteps.vue'),
    children: [
      {
        path: 'sumar',
        name: 'cart-view',
        component: () => import('./CartView.vue'),
      },
      {
        path: 'finalizare',
        name: 'cart-checkout',
        beforeEnter: checkPayment,
        component: () => import('./CartCheckout.vue'),
      },
      {
        path: 'confirmare',
        name: 'post-payment',
        component: () => import('./PostPayment.vue'),
      },
    ],
  },
  {
    path: '/doneaza',
    component: () => import('./BaseSteps.vue'),
    redirect: { name: 'donation', query: { step: 1, type: 'project' } },
    meta: { defaultType: 'project' },

    children: [
      {
        path: '',
        name: 'donation',
        beforeEnter: checkSteps,
        component: () => import('./DonationFormBase.vue'),
      },

      {
        path: '/:pathMatch(.*)*',
        name: 'registration-redirect',
        redirect: {
          name: 'registration',
          query: { step: 1, type: 'individual' },
        },
      },
    ],
  },
  {
    path: '/utilizator',
    children: [
      {
        path: 'contul-meu',
        name: 'my-account',
        component: () => import('./BaseAccount.vue'),
        children: [
          {
            path: '',
            name: 'account-races',
            component: () => import('./AccountRaces.vue'),
          },
          {
            path: 'campion',
            name: 'account-champion',
            component: () => import('./AccountChampion.vue'),
          },
          {
            path: 'donatii',
            name: 'account-donations',
            component: () => import('./AccountDonations.vue'),
          },
          {
            path: 'voluntariat',
            name: 'account-volunteer',
            component: () => import('./AccountVolunteer.vue'),
          },
        ],
      },
      {
        path: 'date',
        name: 'user-settings',
        component: () => import('./ProfileUser.vue'),
      },
      {
        path: 'istoric',
        name: 'user-history',
        component: () => import('./AccountHistory.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  try {
    umami.track()
  } catch {}

  next()
})

export { router }
