import throttle from 'lodash.throttle'

export default class PageMenu {
  constructor() {
    this.article = document.querySelector('article.page-article')
    this.menu = document.querySelector('nav.is-page-menu')

    if (this.menu) {
      requestAnimationFrame(() => {
        this.initMenu()
      })
    }
  }

  getScrollPositions() {
    this.headingPositions = []
    const y = window.scrollY

    this.headings.forEach((e) => {
      this.headingPositions.push(e.getBoundingClientRect().top + y)
    })
  }

  initMenu() {
    // this.menuMobile = document.querySelector('#article-nav-mobile')

    if (!this.menu) return
    const links = this.menu.querySelectorAll('a.is-filter-tab')

    this.menu.addEventListener('click', (e) => {
      e.preventDefault()
      this.scrollToSection(e.target.closest('.button')?.dataset.id, 40)
    })

    // this.menuMobile.addEventListener('change', () => {
    //   this.scrollToSection(this.menuMobile.value, 90)
    // })

    window.addEventListener(
      'resize',
      throttle(() => {
        this.getScrollPositions()
      }, 100)
    )

    let active = null
    const checkScroll = () => {
      const y = window.scrollY

      // section highlight
      let index = this.headingPositions.findIndex((e) => e > y + 200)
      if (index == -1) index = links.length

      if (index > 0 && active != links[index - 1]) {
        if (active) active.classList.remove('is-active')
        active = links[index - 1]
        active.classList.add('is-active')
        // this.menuMobile.value = active.dataset.id
      } else if (index == 0) {
        if (active) active.classList.remove('is-active')
        active = null
      }
    }

    this.headings = this.article.querySelectorAll('.block-heading_block > h2')

    this.getScrollPositions()
    checkScroll()

    window.addEventListener('scroll', throttle(checkScroll, 100))
  }

  scrollToSection(id, offset = 0) {
    if (id) {
      const index = Array.from(this.headings).findIndex((e) => e.id == id)

      window.scrollTo({
        top: this.headingPositions[index] - offset,
        behavior: 'smooth',
      })
    }
  }
}
