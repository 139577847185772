import request from './request'

const api = {
  // Search API
  search: (query) => request.get(`/api/search/?${query}`),

  // Champions API
  getChampionById: (championId) => request.get(`/api/champions/${championId}/`),
  getChampions: () => request.get('/api/champions/'),

  getChampionProfile: () => request.get('/api/champions/me/'),
  updateChampionProfile: (data) => request.post('/api/champions/me/', data),
  deleteChampionProfile: (data) => request.delete('/api/champions/me/'),

  // Donations API
  getDonations: () => request.get('/api/donations/'),
  getDonationById: (donationId) => request.get(`/api/donations/${donationId}/`),
  createDonation: (data) => request.post('/api/donations/new/', data),
  updateDonation: (donationId, data) =>
    // Schema: DonationUpdate
    // Properties: id, amount, donation_type, project, fundraiser, participant, message
    request.post(`/api/donations/${donationId}/update/`, data),
  checkoutDonations: (data) =>
    // Schema: OrderUpdate
    // Properties: id, user, payment_method, order_types, amount, code, paid, legal_name, vat_number, fiscal_number, address, city, county, country, zip_code, lock, account_type
    request.post('/api/donations/checkout/', data),
  getDonationOrderPay: (orderId) =>
    request.get(`/api/donations/orders/${orderId}/pay/`),
  deleteDonation: (donationId) =>
    request.delete(`/api/donations/${donationId}/`),

  // Projects API
  getProjects: () => request.get('/api/projects/'),
  getProjectById: (projectId) => request.get(`/api/projects/${projectId}/`),

  // Races API
  getRaces: () => request.get('/api/races/'),

  // Cart API
  getCart: () => request.get('/api/cart/list/'),
  checkoutCart: (data) => request.post('/api/cart/checkout/', data),
  getOrderByCode: (code) => request.get(`/api/cart/orders/${code}/`),
  payOrder: (code, data) => request.post(`/api/cart/orders/${code}/pay/`, data),

  // Registration API
  getRegistrationById: (userRaceId) =>
    request.get(`/api/registration/${userRaceId}/`),
  updateRegistration: (userRaceId, data) =>
    // Schema: TeamMemberUpdate
    // Properties: id, first_name, last_name, phone, email, birthdate, project, race, wants_tshirt, parent_race
    request.post(`/api/registration/${userRaceId}/update/`, data),
  addTshirtToRegistration: (userRaceId, data) =>
    // Schema: Tshirt
    // Properties: id
    request.post(`/api/registration/${userRaceId}/add_tshirt/`, data),
  inviteMemberToRegistration: (userRaceId) =>
    request.get(`/api/registration/${userRaceId}/invite_member/`),
  removeTshirtFromRegistration: (userRaceId, tshirtId) =>
    request.delete(
      `/api/registration/${userRaceId}/remove_tshirt/${tshirtId}/`
    ),
  checkoutRegistration: (data) =>
    // Schema: Order
    // Properties: id, user, payment_method, order_types, donations, tshirts, user_races, amount, code, paid, legal_name, vat_number, fiscal_number, address, city, county, country, zip_code, lock, account_type
    request.post('/api/registration/checkout/', data),
  createNewRegistration: (data) =>
    // Schema: TeamMemberUpdate
    // Properties: id, first_name, last_name, phone, email, birthdate, project, race, wants_tshirt, parent_race
    request.post('/api/registration/new/', data),
  getRegistrationOrders: () => request.get('/api/registration/orders/'),
  getRegistrationOrderById: (orderId) =>
    request.get(`/api/registration/orders/${orderId}/`),
  createRegistrationOrder: (orderId, data) =>
    // Schema: Order
    // Properties: id, user, payment_method, order_types, donations, tshirts, user_races, amount, code, paid, legal_name, vat_number, fiscal_number, address, city, county, country, zip_code, lock, account_type
    request.post(`/api/registration/orders/${orderId}/`, data),
  updateRegistrationOrderItems: (orderId) =>
    request.get(`/api/registration/orders/${orderId}/update_order_items/`),
  createTeam: (data) => request.post(`/api/registration/teams/`, data),
  getTeamById: (teamId) => request.get(`/api/registration/teams/${teamId}/`),
  addMemberToTeam: (teamId, data) =>
    // Schema: TeamMemberUpdate
    // Properties: id, first_name, last_name, phone, email, birthdate, project, race, wants_tshirt, parent_race
    request.post(`/api/registration/teams/${teamId}/add_member/`, data),
  checkToken: (token) => request.get(`/api/registration/check_token/${token}/`),
  regenerateInviteToken: (teamId) =>
    request.get(`/api/registration/teams/${teamId}/regenerate_invite_token/`),
  registerWithToken: (token, data) =>
    // Schema: TeamMemberUpdate
    // Properties: id, first_name, last_name, phone, email, birthdate, project, race, wants_tshirt, parent_race
    request.post(`/api/registration/teams/register_with_token/${token}/`, data),
  deleteRegistration: (userRaceId) =>
    request.delete(`/api/registration/remove_registration/${userRaceId}/`),
  updateTeamMember: (teamId, userRaceId, data) =>
    // Schema: TeamMemberUpdate
    // Properties: id, first_name, last_name, phone, email, birthdate, project, race, wants_tshirt, parent_race
    request.post(
      `/api/registration/teams/${teamId}/update_member/${userRaceId}/`,
      data
    ),
  updateTeam: (teamId, data) =>
    // Schema: TeamNameUpdate
    // Properties: name
    request.post(`/api/registration/teams/${teamId}/update/`, data),
  deleteTeam: (teamId) => request.delete(`/api/registration/teams/${teamId}/`),
  getRegisteredAdults: () =>
    request.get('/api/registration/registered_adults/'),

  // Tshirts API
  getTshirts: () => request.get('/api/tshirts/'),

  // Users API
  getUsers: () => request.get('/api/users/'),
  getUserSession: () => request.get('/api/users/session/'),
  getUserById: (id) => request.get(`/api/users/${id}/`),
  updateUser: (id, data) =>
    // Schema: User
    // Properties: email, first_name, last_name, phone
    request.put(`/api/users/${id}/`, data),
  partialUpdateUser: (id, data) =>
    // Schema: PatchedUser
    // Properties: email, first_name, last_name, phone
    request.patch(`/api/users/${id}/`, data),
  getCurrentUser: () => request.get('/api/users/me/'),
  getUserAccount: () => request.get('/api/users/my-account/'),
  getCurrentEdition: () => request.get('/api/editions/latest/'),
  deleteUser: () => request.delete(`/api/users/me/`),
  getUserEditions: () => request.get('/api/users/editions/'),
  getUserEditionByYear: (year) => request.get(`/api/users/editions/${year}/`),

  // Volunteers API
  getVolunteersDashboard: () => request.get('/api/volunteers/dashboard/'),
  getVolunteerProfile: () => request.get('/api/volunteers/me/'),
  updateVolunteer: (data) =>
    // Schema: VolunteerUpdate
    // Properties: tshirt, organization, past_volunteer, first_name, last_name, phone, activities
    request.post('/api/volunteers/me/', data),
  deleteCurrentVolunteer: () => request.delete('/api/volunteers/me/'),
  addActivityToVolunteer: (activityId) =>
    request.get(`/api/volunteers/me/add_activity/${activityId}/`),
}

export default api
