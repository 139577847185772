<script setup>
import { FormField } from '@primevue/forms'

const props = defineProps({
  class: {
    type: String,
  },
  grouped: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  name: {
    type: String,
  },
  help: {
    type: [String, Object],
  },
  resolver: {},
})
</script>

<template>
  <FormField v-slot="$field" v-bind="{ name }" :class="['field', props.class]">
    <label class="label" v-if="label" v-html="label" />
    <slot></slot>
    <p
      class="icon-text is-top is-size-16 is-flex has-text-light-400 mt-8"
      v-if="help"
    >
      <span
        class="icon is-size-18"
        :class="[$field?.invalid ? 'has-text-pink-700' : 'has-text-black']"
      >
        <iconify-icon icon="uil:info-circle" />
      </span>
      <span :class="{ 'has-text-pink-700': $field?.invalid }">{{ help }}</span>
    </p>

    <p
      class="icon-text is-size-16 is-flex has-text-pink-700 mt-8"
      v-if="$field?.invalid && $field.error?.message"
    >
      <span>{{ $field.error?.message }}</span>
    </p>
  </FormField>
</template>

<style scoped>
/* Add any additional styling here if needed */
</style>
