import { createApp, defineAsyncComponent } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import PrimeVue from '@/js/services/primevue'
import { router } from './vue/router'

export default class VueComponents {
  constructor(container = document) {
    const components = container.querySelectorAll('[data-vue-component]')
    const apps = []

    components.forEach((component) => {
      const vueComponent = defineAsyncComponent(() =>
        import(`./vue/${component.dataset.vueComponent}.vue`)
      )

      if (vueComponent) {
        const app = createApp(vueComponent)
        PrimeVue(app)

        if (component.dataset.vueComponent == 'BaseRouter') {
          app.use(router)

          try {
            console.log(
              'GOOGLE_RECAPTCHA_SITE_KEY',
              GOOGLE_RECAPTCHA_SITE_KEY.substr(0, 5)
            )
          } catch (error) {
            console.error('GOOGLE_RECAPTCHA_SITE_KEY not found')
          }

          app.use(VueReCaptcha, {
            siteKey: GOOGLE_RECAPTCHA_SITE_KEY,
            loaderOptions: {
              autoHideBadge: true,
              useRecaptchaNet: true,
            },
          })
        }

        app.provide('dataset', component.dataset)
        app.mount(component)
        apps.push(app)
      }
    })

    return apps
  }
}
