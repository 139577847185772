import 'iconify-icon'
// import { register } from 'swiper/element/bundle'

import Accordion from './accordion'
import Carousel from './carousel'
import PageMenu from './page-menu'
import Sharer from './sharer'
import Scrolltop from './scrolltop'

import Modals from './modals'
import Search from './search'
import GLightbox from 'glightbox'

import VueComponents from './vue-component'
import '@/scss/base.scss'

const APP = {
  init() {
    console.log('[Timotion] Base online')

    this.modals = new Modals()
    this.accordion = new Accordion()
    this.search = new Search()
    this.vueComponents = new VueComponents()
    this.pageMenu = new PageMenu()
    this.sharer = new Sharer()
    this.scrolltop = new Scrolltop()

    this.initCarousels()
    this.initLightbox()
  },

  initCarousels() {
    this.carousels = {
      default: new Carousel('.swiper.is-default', {
        slidesPerView: 'auto',
        spaceBetween: 4,
        breakpoints: {
          768: {
            spaceBetween: 8,
          },
          1024: {
            spaceBetween: 16,
          },
          1366: {
            spaceBetween: 24,
          },
        },
      }),

      featured: new Carousel('.swiper.is-featured', {
        autoplay: {
          delay: 2500,
          // disableOnInteraction: false,
        },
        centeredSlides: true,
        loop: true,
        parallax: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        spaceBetween: 8,
        speed: 1500,
      }),

      races: new Carousel('.swiper.is-races', {
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: 0,
      }),

      quotes: new Carousel('.swiper.is-quotes', {
        centeredSlides: true,
        loop: true,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: 8,
        effect: 'coverflow',
        coverflowEffect: {
          rotate: -15,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
      }),
    }
  },

  initLightbox() {
    const lightboxHTML = `
        <div id="glightbox-body" class="glightbox-container">
          <div class="gloader visible"></div>
          <div class="goverlay"></div>
          <div class="gcontainer">
            <div id="glightbox-slider" class="gslider"></div>
            <button class="gnext gbtn button is-rounded is-1by1 is-light-100" tabindex="0" aria-label="Următorul"><span class="icon is-icon-24"><iconify-icon icon="radix-icons:chevron-right"></iconify-icon></span></button>
            <button class="gprev gbtn button is-rounded is-1by1 is-light-100" tabindex="1" aria-label="Precedentul"><span class="icon is-icon-24"><iconify-icon icon="radix-icons:chevron-left"></iconify-icon></span></button>
            <button class="gclose gbtn button is-rounded is-1by1 is-light-100" tabindex="2" aria-label="Închide"><span class="icon is-icon-16"><iconify-icon icon="uil:times" class=""></iconify-icon></span></button>
          </div>
        </div>`

    this.lightbox = GLightbox({
      touchNavigation: true,
      loop: true,
      lightboxHTML,
    })

    // const trigger = document.querySelector('[data-trigger="glightbox"]')
    // if (trigger)
    //   trigger.addEventListener('click', () => {
    //     this.lightbox.openAt(trigger.dataset.index)
    //   })
  },
}

document.addEventListener('DOMContentLoaded', () => {
  APP.init()
})

export default APP
