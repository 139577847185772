import PrimeVue from 'primevue/config'

// import { Form, Field } from 'vee-validate'
import { Form } from '@primevue/forms'
import VField from '@/js/vue/form/VField.vue'

import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import DataTable from 'primevue/datatable'
import DatePicker from 'primevue/datepicker'
import Dialog from 'primevue/dialog'
import DialogService from 'primevue/dialogservice'
import DynamicDialog from 'primevue/dynamicdialog'
import FileUpload from 'primevue/fileupload'
import IconField from 'primevue/iconfield'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import MultiSelect from 'primevue/multiselect'
import ProgressSpinner from 'primevue/progressspinner'
import RadioButton from 'primevue/radiobutton'
import Select from 'primevue/select'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import ToggleSwitch from 'primevue/toggleswitch'
import Tooltip from 'primevue/tooltip'
import TreeTable from 'primevue/treetable'

import KeyFilter from 'primevue/keyfilter'

import ro from './primevue-ro.json'
import Aura from '@primevue/themes/aura'

export default (app) => {
  app.use(PrimeVue, {
    locale: ro,
    theme: {
      preset: Aura,
      options: {
        prefix: 'p',
        darkModeSelector: false,
        cssLayer: false,
      },
    },
    pt: {
      // Button: { root: { class: 'button' } },
      InputText: { root: { class: 'input' } },
      InputNumber: { pcinputtext: { class: 'input' } },
      Select: { root: { class: 'input' } },
      MultiSelect: { root: { class: 'input' } },
    },
  })

  app.use(ConfirmationService)
  app.use(DialogService)
  app.use(ToastService)

  app.directive('keyfilter', KeyFilter)
  app.directive('tooltip', Tooltip)

  app.component('Button', Button)
  app.component('Checkbox', Checkbox)
  app.component('Column', Column)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('DataTable', DataTable)
  app.component('DatePicker', DatePicker)
  app.component('Dialog', Dialog)
  app.component('DynamicDialog', DynamicDialog)
  app.component('FileUpload', FileUpload)
  app.component('IconField', IconField)
  app.component('InputIcon', InputIcon)
  app.component('InputGroup', InputGroup)
  app.component('InputGroupAddon', InputGroupAddon)
  app.component('InputText', InputText)
  app.component('InputNumber', InputNumber)
  app.component('MultiSelect', MultiSelect)
  app.component('ProgressSpinner', ProgressSpinner)
  app.component('RadioButton', RadioButton)
  app.component('Select', Select)
  app.component('Textarea', Textarea)
  app.component('Toast', Toast)
  app.component('ToggleSwitch', ToggleSwitch)
  app.component('TreeTable', TreeTable)

  // app.component('Field', Field)

  app.component('Form', Form)
  app.component('VField', VField)
}
