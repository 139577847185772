import Swiper from 'swiper'

import {
  EffectFade,
  EffectCoverflow,
  // FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Thumbs,
} from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/mousewheel'
import 'swiper/css/effect-fade'

export default class Carousel {
  constructor(selector, options = {}, container) {
    this.elements = container
      ? container.querySelectorAll(selector)
      : document.querySelectorAll(selector)

    // console.log(selector, elements, container)

    this.elements.forEach((element, index) => {
      const navigation = {
        nextEl: element.querySelector('.swiper-button.is-next'),
        prevEl: element.querySelector('.swiper-button.is-prev'),
      }

      this.instances = new Swiper(element, {
        modules: [
          Navigation,
          Pagination,
          Mousewheel,
          // FreeMode,
          EffectFade,
          EffectCoverflow,
          Thumbs,
        ],
        mousewheel: {
          forceToAxis: true,
        },
        navigation,
        ...options,
      })
    })
  }
}
